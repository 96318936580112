import React, { useEffect, useState } from "react"
import ResumeApplyContainer from "src/views/components/ResumeApply/ResumeApplyContainer"

interface IResumeApplyContext {
  vacancy_id: number | null
  setVacancyId: (num: any) => any
  setResumeModalStep: (num: any) => any
  closeAndResetModal: any
}
// Creating Context
export const ResumeApplyContext = React.createContext<IResumeApplyContext>({
  vacancy_id: null,
  setVacancyId: () => null,
  setResumeModalStep: () => null,
  closeAndResetModal: () => null
})
const ResumeApplyProvider = ({ children }: any) => {
  const [vacancy_id, setVacancyId] = useState<number | null>(null)
  const [resumeModalStep, setResumeModalStep] = useState<number | null>(null)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const closeAndResetModal = () => {
    setVacancyId(null)
    setResumeModalStep(null)
  }

  useEffect(() => {
    if (vacancy_id) {
      setResumeModalStep(1)
    }
  }, [vacancy_id])

  const context = {
    vacancy_id,
    setVacancyId,
    setResumeModalStep,
    closeAndResetModal
  }

  if (!isClient) return null
  return (
    <ResumeApplyContext.Provider value={context}>
      <>
        <ResumeApplyContainer
          closeAndResetModal={closeAndResetModal}
          vacancy_id={vacancy_id}
          resumeModalStep={resumeModalStep}
        />
        {children}
      </>
    </ResumeApplyContext.Provider>
  )
}

export default ResumeApplyProvider
