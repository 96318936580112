// ** Tailwind Styled
import tw from "tailwind-styled-components"

// ACC - About Company Card
export const ACCLayout = tw.div`mt-5 border border-neutral-30 bg-neutral-5 rounded-2xl p-5`
export const ACCHeader = tw.div`flex justify-between items-center`
export const ACCHeaderLogoCompanyWrapper = tw.div`flex items-center gap-2`
export const ACCHeaderCompanyWrapper = tw.div`flex flex-col gap-0.5`
export const ACCStats = tw.div`flex items-center gap-9 mt-5 mb-1`
export const ACCNotificationButton = tw.button`hover:bg-primary-5 border border-primary-100 rounded-full py-1.5 px-5 flex items-center gap-1`
