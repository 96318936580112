import { cva, VariantProps } from "class-variance-authority"
import Image from "next/image"
import React from "react"
import { cx } from "class-variance-authority"

interface IconButtonProps {
  height?: number
  width?: number
  src: string
  alt?: string
  onClick?: () => void
}

const buttonStyles = cva("focus:outline-none", {
  variants: {
    intent: {
      primary: "bg-primary-100",
      secondary: "bg-primary-100/10",
      transparent: "bg-transparent"
    },
    size: {
      big: "",
      medium: "p-1.5",
      small: ""
    }
  },
  defaultVariants: {
    intent: "transparent",
    size: "medium"
  }
})

interface Props extends IconButtonProps, VariantProps<typeof buttonStyles> {}

const IconButton = ({ intent, src, height = 20, width = 20, onClick, alt = "icon" }: Props) => {
  return (
    <button type="button" onClick={onClick} className={buttonStyles({ intent })}>
      <Image src={src} alt={alt} width={height} height={width} />
    </button>
  )
}

export default IconButton
