import Image from "next/image"
import Typography, { TypographyProps } from "../../typography"
import { cx } from "class-variance-authority"

type IconTextProps = {
  text: string
  boldText?: string
  icon: string
  width?: number
  height?: number
  className?: string
} & TypographyProps

const IconText = ({ text, boldText, icon, width = 20, height = 5, type, color, weight, className }: IconTextProps) => {
  return (
    <div className={cx("flex items-start gap-1 py-1", className)}>
      <Image src={icon} width={width} height={height} alt={text} />
      <Typography type={type} color={color} weight={weight}>
        {boldText && <span className="font-tbcx-medium">{boldText} </span>}
        {text}
      </Typography>
    </div>
  )
}

export default IconText
