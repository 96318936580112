import { useTranslation } from "next-i18next"
import * as yup from "yup"

export const MAX_FILE_SIZE: number = 1024 * 1024 * 10 // 5  mb

export const ResumeApplySchema = () => {
  const { t } = useTranslation("common")
  return yup.object().shape({
    name: yup.string().required(t("NAME_SURNAME_MANDATORY")),
    profession: yup.string().required(t("PROFESSION_MANDATORY")),
    email: yup.string().required(t("EMAIL_MANDATORY")).email(t("WRONG_EMAIL_FORMAT")),
    mobile: yup.number().typeError(t("PHONE_MANDATORY")).required(t("PHONE_MANDATORY")).positive(t("WRONG_FORMAT")),
    cv: yup.lazy(value =>
      value
        ? yup.mixed().test("fileSize", t("FILE_MUST_BE_LESS"), () => value.size <= MAX_FILE_SIZE)
        : yup.mixed().required(t("FILE_UPLOAD_MANDATORY"))
    ),
    sms: yup.lazy(value => (value ? yup.string() : yup.string().nullable()))
  })
}
