export const convertSalaryVariantsToText = (from: number | null, to: number | null, type: string, t: any): string => {
  if (from && to) {
    return `${from} - ${to}`
  }

  if (from && !to) {
    return from.toString()
  }

  return t("NEGOTIABLE")
}

export const getAverageVacancySalary = (from: number, to: number | null, type: string): number | null => {
  if (from && !to) {
    return from
  }

  if (from && to) {
    return (from + to) / 2
  }

  return null
}

export function formatNumberWithCommas(number: number) {
  // Convert number to string and split into parts before and after the decimal point
  const parts = number.toString().split(".")

  // Insert commas for thousands separator
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  // Join parts back together with comma added
  return parts.join(".")
}
