import Tippy from "@tippyjs/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import Typography from "src/views/components/typography"
import { formatNumberWithCommas } from "../../../../utils/helpers/string/convertSalaryVariantsToText"
import { useTranslation } from "next-i18next"

interface Props {
  avgSalary: number | null
}

const AverageSalaryAnalytics = ({ avgSalary }: Props) => {
  const { t } = useTranslation("common")
  if (!avgSalary) return null
  const minSalary = 0
  const maxSalary = 6000
  const circlePosition = () => {
    let salaryPercentage = Math.round((avgSalary / (maxSalary + minSalary)) * 100)

    if (salaryPercentage > 98) {
      salaryPercentage = 96
    }
    if (salaryPercentage < 0) {
      salaryPercentage = 0
    }

    return `${salaryPercentage}%`
  }

  return (
    <div className="relative z-20 mt-6 flex max-w-[691px] gap-1">
      <div className="flex flex-1 flex-col items-center gap-3">
        <Typography type="text" weight="medium">
          <span>&nbsp;</span>
        </Typography>
        <div className="h-3 w-full  rounded-l-full bg-[#F2F3F6] "></div>
        <div className="flex items-center gap-1 self-end">
          <Typography type="text" weight="medium">
            2 000
          </Typography>
          <Image src={"/images/gel_small.svg"} width={10} height={11} alt="gel" />
        </div>
      </div>
      <div className="flex flex-1 flex-col items-center gap-3">
        <Typography type="text" weight="medium">
          {t("MIDDLE")}
        </Typography>
        <div className={cx("h-3 w-full", avgSalary > 2000 && avgSalary < 4000 ? "bg-warning-100" : "bg-[#F2F3F6]")} />
        <div className="flex items-center gap-1 self-end">
          <Typography type="text" weight="medium">
            4 000
          </Typography>
          <Image src={"/images/gel_small.svg"} width={10} height={11} alt="gel" />
        </div>
      </div>
      <div className="relative flex flex-1 flex-col items-center gap-3 text-ellipsis text-nowrap ">
        {avgSalary >= 4000 && (
          <div
            className="absolute -bottom-3 z-0 h-14 w-full rounded-r-md"
            style={{
              background: "linear-gradient(180deg, rgba(55, 179, 126, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%)"
            }}
          />
        )}

        <Typography type="text" weight="medium">
          {t("HIGH_SALARY")}
        </Typography>
        <div className={cx("z-10 h-3 w-full rounded-r-full", avgSalary >= 4000 ? "bg-success-100" : "bg-[#F2F3F6]")} />
        <div className=" z-10 flex items-center gap-1 self-end ">
          <Typography type="text" weight="medium">
            {avgSalary > 6000 ? formatNumberWithCommas(avgSalary) : "6 000"}
          </Typography>
          <Image src={"/images/gel_small.svg"} width={10} height={11} alt="gel" />
        </div>
      </div>
      {avgSalary > 2000 && (
        <Tippy
          placement="bottom"
          content={
            <span className="flex flex-col items-center rounded-full px-3 py-1">
              <Typography type="small" className="text-white">
                {t("THIS_SALARY")}
              </Typography>
              <Typography type="text" className="font-tbcx-bold text-white ">
                {formatNumberWithCommas(avgSalary)}
              </Typography>
            </span>
          }
        >
          <div
            className="absolute 
          top-1/2 
          z-10 h-[30px] w-[30px] -translate-y-1/2 transform cursor-pointer rounded-full border-4 border-secondary-100 bg-white"
            style={{ marginLeft: circlePosition() }}
          ></div>
        </Tippy>
      )}
    </div>
  )
}

export default AverageSalaryAnalytics
