import { TFunction } from "next-i18next"

export const renderVacancySalary = (
  salary_from: number | string,
  salary_to: number | string,
  show_salary: number,
  salary_type: string,
  t: TFunction
) => {
  if (show_salary && salary_from) {
    if (salary_to) {
      return `${salary_from} - ${salary_to} ₾`
    } else {
      return `${salary_from} ₾`
    }
  }

  return salary_type !== "fixed" ? t(salary_type.toUpperCase()) : "შეთანხმებით"
}
