export const extractUrlFromDescription = (description: string | null): any => {
  // Handle null or empty description
  if (!description) {
    console.warn("No description provided")
    return null // Early return if description is null or empty
  }

  // Function to extract URLs from HTML
  const extractUrlsFromHtml = (html: string): string | null => {
    // Use a regex to find all href values in anchor tags
    const urlMatches = html.match(/<a[^>]+href=["']([^"']+)["']/i)
    const url = urlMatches ? urlMatches[1].toString() : null
    return url // Return the first URL found or null
  }

  // Check if the description contains HTML tags
  if (/<[a-z][\s\S]*>/i.test(description)) {
    return extractUrlsFromHtml(description) // Extract URLs if it's HTML
  }
}
