import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import Button from "src/views/components/common/Button/Button"
import Typography from "src/views/components/typography"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import useOriginUrl from "src/hooks/useOriginUrl"
import useUser from "src/hooks/useUser"
import usePersonApi from "src/server/queryHooks/usePersonApi"
import ModalStep from "../ModalStep"
// Skeleton CSS
import "react-loading-skeleton/dist/skeleton.css"
import Spinner from "src/views/components/UI/loaders/Spinner"
import { useTranslation } from "next-i18next"

// Step 1
const ResumeStepLoginOrFinishProfile = ({ vacancy_id }: { vacancy_id: number }) => {
  const { t } = useTranslation("common")
  const { setResumeModalStep, closeAndResetModal } = useContext(ResumeApplyContext)
  const { connectedUser } = useUser()
  const { naturalPersonData, naturalPersonQuery } = usePersonApi()
  const { initiateLogin } = useOriginUrl()
  const router = useRouter()

  useEffect(() => {
    if (connectedUser && naturalPersonData) {
      setResumeModalStep(5)
    }
  }, [connectedUser, naturalPersonData])

  if (naturalPersonQuery.isFetching) {
    return <Spinner className="flex h-full items-center justify-center" size={24} />
  }

  // If user is NOT authenticated
  if (!connectedUser) {
    return (
      <ModalStep
        title={t("SEND_RESUME")}
        subtitle={t('FOR_SENDING_RESUME')}
      >
        <div className="flex flex-1 flex-col items-center justify-start ">
          <Image
            src="/images/logos/tnet200.svg"
            width={200}
            height={200}
            alt="tnet logo"
            className="mb-[62px] mt-[62px]"
          />
          <div className="w-full px-16">
            <Button text={t("ENTER")} intent="tertiary" className="w-full" weight={"regular"} onClick={initiateLogin} />
            <div className="flex w-full items-center  gap-2.5 pt-4 ">
              <div className="h-[1px] flex-1 bg-neutral-40"></div>
              <Typography type="body" className="text-secondary-50">
                {t("OR")}
              </Typography>
              <div className="h-[1px] flex-1 bg-neutral-40"></div>
            </div>

            <Button
              text={t("SEND_MANUALLY")}
              intent="text"
              className="mt-3 w-full "
              weight="medium"
              type="button"
              onClick={() => setResumeModalStep(2)}
            />
          </div>
        </div>
      </ModalStep>
    )
  }

  // If user IS authenticated but has not completed register wizard
  if (connectedUser && !naturalPersonData) {
    return (
      <ModalStep
        title={t("SEND_RESUME")}
        subtitle={t("FILL_ALL_FIELDS_FOR_SENDING_RESUME")}
      >
        <div className="flex flex-1 flex-col items-center">
          <Image
            src="/images/girl_in_laptop.svg"
            width={425}
            height={319}
            alt="girl mail"
            className="-mr-4 mb-auto mt-11"
          />
          <Link
            href={{
              pathname: "/register-wizard",
              query: router.query
            }}
            className="w-full"
          >
            <Button intent="primary" onClick={closeAndResetModal} text={t("CONTINUE")} className="w-full" />
          </Link>
        </div>
      </ModalStep>
    )
  }

  // If user IS authenticated and has completed register wizard
  // Return loader and apply to vacancy
  if (connectedUser && naturalPersonData) {
    return <Skeleton count={22} />
  }

  return null
}

export default ResumeStepLoginOrFinishProfile
