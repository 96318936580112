import { useQueryClient } from "@tanstack/react-query"
import { useContext } from "react"
import toast from "react-hot-toast"
import Button from "src/views/components/common/Button/Button"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import useVacancy from "src/server/queryHooks/useVacancy"
import ModalStep from "../ModalStep"
import { useTranslation } from "next-i18next"
import { NODE_ENV } from "src/env"

interface Props {
  vacancy_id: number
}
const ResumeConfirmationStep = ({ vacancy_id }: Props) => {
  const { t } = useTranslation("common")
  const queryClient = useQueryClient()

  const { closeAndResetModal, setResumeModalStep } = useContext(ResumeApplyContext)
  const { vacancyUserApplyMutation, appliedVacanciesQuery: vacancyApplicationsQuery } = useVacancy()
  const onMutate = () => {
    vacancyUserApplyMutation.mutate(
      {
        vacancy_id
      },
      {
        onSuccess: variables => {
          setResumeModalStep(4)
          queryClient.setQueryData([QUERY_KEYS.GET_SAVED_APPLIED_STATUS], (oldData: any) => {
            if (!oldData) {
              return { applied: { [vacancy_id]: true }, saved: {} }
            }
            const newStatus = { ...oldData }
            newStatus.applied[vacancy_id] = true
            return newStatus
          })
          if (NODE_ENV === "production") {
            window.gtag("event", "cv_send", {
              currency: "USD",
              value: 0
            })
          }
          // Send POST request to Google Analytics
        },
        onError: error => {
          closeAndResetModal()
          toast.error(t("RESUME_ALREADY_SENT"))
        }
      }
    )
  }

  return (
    <ModalStep title={t("SURE_TO_SEND")} textLeft>
      <div className="flex justify-end gap-3 border-t border-neutral-40 pt-9">
        <Button intent="ghost" onClick={closeAndResetModal} text={t("CLOSE")} className="" />
        <Button
          intent="primary"
          loading={vacancyUserApplyMutation.isLoading}
          onClick={onMutate}
          text={t("SEND")}
          className=""
        />
      </div>
    </ModalStep>
  )
}

export default ResumeConfirmationStep
