import React from "react"
import Typography from "src/views/components/typography"

const Vip = () => {
  return (
    <div className="flex h-[14px] w-[25px] shrink-0 items-center justify-center rounded border border-[#FD4100] bg-[#FD541A]">
      <Typography type="mini" weight="medium" className="text-white">
        VIP
      </Typography>
    </div>
  )
}

export default Vip
