import axios from "axios"
import { API_URL } from "src/env"
import VacancyService from "../services/VacancyService"

const useResumeApi = () => {}

export interface getCheckApplicantCredentialsInterface {
  vacancy_id: number
  email: string
  mobile: string
}
export const getCheckApplicantCredentials = async (data: getCheckApplicantCredentialsInterface) => {
  try {
    const response: any = await VacancyService.getVacanciesCheckApplicantCredentials(data)

    return response.data
  } catch (e: any) {
    console.error("error", e)
    throw e // Rethrow the error
  }
}

export default useResumeApi
