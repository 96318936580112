import { useTranslation } from "next-i18next"

export const RenderVacancyExperience = (experience_from: number, experience_to: number) => {
  const { t } = useTranslation("common")
  if (experience_from && experience_to) {
    return experience_from + "-" + experience_to + ` ${t("YEAR")}`
  }
  if (!experience_from && experience_to) {
    return 0 + "-" + experience_to + ` ${t("YEAR")}`
  }

  if (experience_from && !experience_to) {
    return experience_from + "+" + ` ${t("YEAR")}`
  }

  if (!experience_from && !experience_to) {
    return t("WITHOUT_EXPERIENCE")
  }

  if (experience_from && experience_to) {
    return experience_from + "-" + experience_to + ` ${t("YEAR")}`
  }
}

export const translateLevelToGeorgian = (levels: { experience_level: string }[]) => {
  const experience: Record<string, string> = {
    "mid-level": "საშუალო",
    senior: "უფროსი სპეციალისტი",
    head: "წამყვანი სპეციალისტი",
    junior: "დამწყები"
  }

  return levels?.length ? `- ${levels.map(level => experience[level.experience_level]).join(", ")}` : ""
}

// ენების მთარგმნელი
export const translateDegreeToGeorgian = (values: string[]): string[] => {
  const translations: Record<string, string> = {
    bachelor: "ბაკალავრი",
    master: "მაგისტრი",
    course_graduate: "კურსდამთავრებული",
    doctorate: "დოქტორი",
    academician: "აკადემიკოსი",
    student: "სტუდენტი",
    specialist: "სპეციალისტი",
    professor: "პროფესორი",
    resident: "რეზიდენტი",
    aspirant: "ასპირანტი",
    without_education: "განათლების გარეშე"
  }

  return values.map(value => translations[value] || value)
}

// Langauge object to georgia
export const translateDegreeToGeorgianObject: any = {
  bachelor: "ბაკალავრი",
  master: "მაგისტრი",
  course_graduate: "კურსდამთავრებული",
  doctorate: "დოქტორი",
  academician: "აკადემიკოსი",
  student: "სტუდენტი",
  specialist: "სპეციალისტი",
  professor: "პროფესორი",
  resident: "რეზიდენტი",
  aspirant: "ასპირანტი",
  without_education: "განათლების გარეშე"
}
