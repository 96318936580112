import { useContext } from "react"
import Button from "src/views/components/common/Button/Button"
import Typography from "src/views/components/typography"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import ModalStep from "../ModalStep"
import { useTranslation } from "next-i18next"

const ResumeSuccessStep = () => {
  const { t } = useTranslation("common")
  const { closeAndResetModal } = useContext(ResumeApplyContext)

  return (
    <ModalStep title="" subtitle="">
      <div className="flex flex-1 flex-col items-center">
        <div className="mb-9 mt-20" id="email-icon">
          <svg width="361" height="290" viewBox="0 0 361 290" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M86.4021 149.761C87.6283 153.593 86.862 157.707 84.3481 160.846C83.9302 161.363 84.7135 162.044 85.1314 161.527C87.8812 158.081 88.7201 153.595 87.3837 149.424C87.1634 148.747 86.209 149.124 86.4021 149.761Z"
              fill="#161616"
            />
            <path
              d="M87.6249 150.176C88.8913 149.824 90.3878 149.375 91.652 149.939C93.5412 150.729 93.2983 152.338 93.1167 153.994C92.8292 156.192 92.2777 158.48 90.5083 159.968C90.0018 160.397 90.5757 161.249 91.0822 160.819C93.5203 158.793 94.4919 155.072 94.3205 151.973C94.0646 148.224 90.0035 148.455 87.3216 149.201C86.6578 149.353 86.995 150.335 87.6249 150.176Z"
              fill="#161616"
            />
            <path
              d="M85.3632 161.783C87.2177 162.391 89.2638 162.015 90.804 160.8C91.3105 160.37 90.7366 159.519 90.2301 159.948C88.8927 161.027 87.2124 161.333 85.555 160.799C84.93 160.571 84.7316 161.59 85.3632 161.783Z"
              fill="#161616"
            />
            <path
              d="M96.0617 105.246C96.6682 104.483 98.3398 102.413 99.2808 104.816C99.4523 105.202 99.2671 106.152 99.2689 106.505C99.2658 107.244 99.3579 108.037 99.7612 108.679C101.114 110.775 102.864 108.121 104.444 109.416C106.453 111.04 103.045 112.983 104.606 114.732C104.981 115.157 105.49 115.256 106.039 115.152C106.405 115.082 106.736 114.83 107.109 114.726C108.47 114.428 108.693 115.634 108.603 116.638C108.578 116.95 108.714 117.153 109.013 117.246C109.38 117.353 109.713 117.453 110.08 117.56C110.711 117.753 110.91 116.735 110.278 116.541C109.911 116.435 109.578 116.335 109.212 116.228C109.348 116.431 109.485 116.633 109.622 116.836C109.698 115.9 109.76 114.679 109.017 113.971C108.22 113.181 107.503 113.605 106.575 113.847C106.128 113.971 105.657 114.584 105.238 113.657C105.094 113.312 105.721 112.447 105.855 112.121C106.096 111.605 106.257 111.143 106.193 110.567C106.087 109.665 105.479 108.807 104.683 108.37C103.704 107.792 103.109 108.134 102.105 108.22C101.211 108.292 100.375 108.059 100.248 107.083C100.213 106.724 100.258 106.31 100.29 105.964C100.294 105.401 100.339 104.811 100.166 104.249C99.2708 101.432 96.4444 103.101 95.2586 104.667C94.8605 105.082 95.6438 105.763 96.0617 105.246Z"
              fill="#161616"
            />
            <path
              d="M103.261 103.372C104.228 102.75 105.041 102.557 105.778 103.475C106.372 104.225 106.196 104.402 106.241 105.256C106.284 105.758 106.334 106.226 106.675 106.645C107.098 107.185 107.675 107.297 108.333 107.355C108.958 107.406 109.75 107.138 110.133 107.882C110.311 108.234 110.145 108.906 110.072 109.279C109.908 110.304 109.67 111.526 110.751 112.124C111.071 112.292 111.477 112.195 111.763 112.357C112.203 112.266 112.353 112.401 112.301 112.849C112.384 113.147 112.399 113.432 112.299 113.764C112.316 114.578 112.509 115.215 113.014 115.877C113.404 116.411 114.255 115.837 113.866 115.303C113.033 114.154 114.004 112.969 113.32 111.955C112.61 110.901 110.967 111.92 110.979 110.231C110.986 108.929 111.792 107.324 110.215 106.559C109.086 106.022 107.599 106.965 107.272 105.211C107.132 104.479 107.38 103.752 107.03 103.015C106.194 101.337 103.979 101.68 102.653 102.514C102.092 102.863 102.694 103.755 103.261 103.372Z"
              fill="#161616"
            />
            <path
              d="M216.007 190.831C215.009 190.531 216.926 188.826 217.265 188.716C218.165 188.433 219.069 188.856 219.555 189.62C220.601 191.304 219.071 193.19 217.232 193.043C216.574 192.985 216.375 194.003 217.034 194.061C218.56 194.182 220.076 193.456 220.706 192.029C221.33 190.635 220.751 188.902 219.506 188.061C218.288 187.26 216.73 187.484 215.718 188.52C214.84 189.406 214.274 191.41 215.843 191.856C216.44 192.043 216.639 191.025 216.007 190.831Z"
              fill="#161616"
            />
            <path
              d="M209.555 187.424C208.967 186.641 210.067 185.516 210.743 186.388C211.453 187.266 210.107 188.025 209.439 187.296C209.009 186.79 208.328 187.573 208.758 188.079C209.365 188.761 210.484 188.803 211.254 188.284C212.146 187.683 212.202 186.496 211.547 185.699C210.892 184.902 209.765 184.717 208.989 185.447C208.3 186.088 208.156 187.187 208.71 187.964C209.092 188.532 209.978 187.965 209.555 187.424Z"
              fill="#161616"
            />
            <path
              d="M335.794 166.751C334.903 173.13 330.563 178.59 324.573 180.946C323.964 181.18 324.301 182.162 324.91 181.928C331.239 179.462 335.849 173.702 336.812 166.949C336.904 166.298 335.886 166.099 335.794 166.751Z"
              fill="#161616"
            />
            <path
              d="M336.327 167.63C339.123 170.64 336.959 174.693 334.612 177.16C332.392 179.51 329.021 182.165 325.597 180.934C324.972 180.707 324.509 181.638 325.134 181.866C329.257 183.303 333.176 180.368 335.793 177.425C338.403 174.516 340.036 170.114 337.008 166.846C336.538 166.367 335.857 167.15 336.327 167.63Z"
              fill="#161616"
            />
            <path
              d="M56.496 81.7539C57.9465 85.7004 58.4758 89.8549 57.9811 94.0213C57.9233 94.6794 58.9416 94.8778 58.9993 94.2197C59.4998 89.8431 58.9969 85.5528 57.4776 81.4167C57.2167 80.767 56.235 81.1042 56.496 81.7539Z"
              fill="#A7A7A7"
            />
            <path
              d="M60.855 81.4408C61.9983 84.9751 62.3081 88.6289 61.8381 92.3069C61.7464 92.9584 62.7646 93.1568 62.8564 92.5053C63.3594 88.6576 63.0148 84.8209 61.8367 81.1036C61.6436 80.4671 60.662 80.8043 60.855 81.4408Z"
              fill="#A7A7A7"
            />
            <path
              d="M54.9548 88.2176C58.2847 86.8583 61.8093 86.4881 65.3863 87.1146C66.0312 87.2402 66.2296 86.222 65.5847 86.0964C61.8719 85.4434 58.1304 85.8418 54.6111 87.2699C54.0019 87.5035 54.339 88.4852 54.9548 88.2176Z"
              fill="#A7A7A7"
            />
            <path
              d="M54.0461 92.339C58.1822 90.8197 62.4989 90.1811 66.9011 90.3694C67.5659 90.3932 67.4861 89.356 66.8214 89.3322C62.3173 89.124 57.9195 89.8173 53.7089 91.3573C53.0997 91.5909 53.4369 92.5726 54.0461 92.339Z"
              fill="#A7A7A7"
            />
            <path
              d="M250.089 147.903C248.375 148.379 247.368 150.297 248.433 151.878C249.007 152.73 249.658 152.645 250.453 153.082C250.963 153.358 251.508 153.816 251.301 154.339C251.16 154.7 250.727 154.932 250.464 155.198C250.039 155.573 249.532 156.003 249.291 156.52C249.029 157.138 249.073 157.816 249.496 158.356C250.117 159.147 251.181 158.931 251.916 159.497C253.455 160.642 249.672 163.604 252.153 164.792C252.765 165.088 253.228 164.156 252.616 163.861C251.583 163.378 253.344 161.572 253.571 160.947C253.752 160.383 253.762 159.786 253.4 159.293C252.465 157.948 248.839 158.475 251.126 155.961C251.841 155.184 253.037 154.29 252.617 153.01C252.486 152.597 252.186 152.328 251.798 152.146C251.404 151.999 250.746 151.941 250.346 151.828C248.566 151.376 248.753 149.334 250.432 148.851C251.056 148.725 250.719 147.744 250.089 147.903Z"
              fill="#A7A7A7"
            />
            <path
              d="M256.513 155.073C255.307 155.296 255.035 156.511 255.535 157.56C255.851 158.291 256.537 158.565 257.028 159.119C258.248 160.449 256.821 161.087 256.345 162.262C255.528 164.287 257.907 164.011 258.287 165.494C258.612 166.72 258.164 167.936 259.456 168.717C260.021 169.073 260.484 168.142 259.919 167.785C259.001 167.254 259.501 165.414 259.103 164.561C258.986 164.257 258.782 164.041 258.584 163.791C258.468 163.662 258.258 163.657 258.169 163.569C257.938 163.489 257.801 163.286 257.8 162.933C257.384 162.535 257.376 162.217 257.849 161.957C258.34 161.066 259.063 160.608 258.61 159.498C258.445 159.078 258.057 158.721 257.73 158.41C257.464 158.147 255.522 156.36 256.62 156.151C257.25 155.992 257.17 154.954 256.513 155.073Z"
              fill="#A7A7A7"
            />
            <path
              d="M308.735 236.377C306.602 234.129 307.73 230.509 310.676 229.674C312.91 229.052 315.509 230.545 316.171 232.752C316.833 234.96 315.367 237.599 313.16 238.261C310.844 238.937 308.415 237.477 307.604 235.311C307.371 234.702 306.389 235.039 306.623 235.648C307.654 238.491 310.966 240.299 313.91 239.111C316.705 237.965 318.22 234.349 316.85 231.616C315.501 228.958 312.009 227.714 309.371 229.138C306.307 230.76 305.75 234.703 308.082 237.201C308.525 237.639 309.206 236.856 308.735 236.377Z"
              fill="#A7A7A7"
            />
            <path
              d="M72.5688 200.509C73.1802 199.36 73.7894 199.127 74.3219 199.829C74.4783 199.93 74.6282 200.065 74.7508 200.159C74.9751 200.274 75.2674 200.401 75.5116 200.413C76.0339 200.445 76.5007 200.218 76.8177 199.857C77.9307 198.665 77.2383 197.156 76.5947 195.939C76.2527 195.344 75.5704 194.506 75.8243 193.745C76.251 192.278 77.0145 193.061 77.781 193.105C78.3373 193.143 78.8521 193.032 79.2229 192.576C79.5804 192.187 79.6249 191.597 79.6496 191.109C79.6686 190.83 79.6263 190.505 79.6792 190.234C79.5804 189.475 80.0058 189.099 80.9553 189.108C81.337 189.5 81.6376 189.946 81.8505 190.481C82.1181 191.096 83.0997 190.759 82.8322 190.143C82.1671 188.675 79.7957 186.381 78.832 188.976C78.3638 190.294 79.3419 191.965 77.2732 191.914C76.2559 191.892 75.5771 191.76 75.0467 192.854C74.5901 193.751 74.7849 194.741 75.2164 195.6C75.4218 195.992 75.6677 196.357 75.8797 196.715C76.153 197.121 76.3179 197.541 76.4016 198.015C75.915 198.343 75.4622 198.677 74.9756 199.005C74.6569 199.014 74.0019 198.217 73.5673 198.097C71.6251 197.578 70.0572 200.02 71.8604 201.252C72.4524 201.649 73.1335 200.866 72.5688 200.509Z"
              fill="#A7A7A7"
            />
            <path
              d="M81.1349 200.663C80.7638 199.675 80.1798 198.152 80.7713 197.105C81.2082 196.309 82.4349 196.161 83.0763 195.581C84.0619 194.681 84.2313 193.269 83.7235 192.078C83.5348 192.323 83.3188 192.528 83.1302 192.773C85.0384 193.286 86.8924 192.449 87.8331 190.695C88.1624 190.09 87.2244 189.66 86.9017 190.232C86.1827 191.571 84.8021 192.148 83.3286 191.755C82.9618 191.648 82.5977 192.07 82.7352 192.449C83.0508 193.18 83.042 194.13 82.4619 194.757C81.9355 195.288 81.3859 195.216 80.8586 195.572C80.372 195.9 80.0211 196.254 79.8003 196.845C79.2576 198.184 79.7332 199.721 80.1872 201.007C80.3802 201.644 81.3619 201.306 81.1349 200.663Z"
              fill="#A7A7A7"
            />
            <path
              d="M218.96 45.4855C220.356 47.9064 221.006 50.5344 220.959 53.3083C220.935 53.9731 221.973 53.8933 221.996 53.2286C222.043 50.2783 221.29 47.4541 219.819 44.8776C219.47 44.3165 218.618 44.8904 218.96 45.4855Z"
              fill="#A7A7A7"
            />
            <path
              d="M219.823 45.7593C221.182 44.9319 226.605 41.6905 228.07 43.2091C228.8 43.9853 229.275 45.5224 229.64 46.5446C230.094 47.8309 230.399 49.1586 230.595 50.5004C230.68 51.151 231.717 51.0712 231.633 50.4206C231.366 48.713 230.958 47.0128 230.319 45.4087C229.921 44.3799 229.343 42.6466 228.376 42.0004C227.288 41.2598 225.623 42.0277 224.526 42.4129C222.678 43.0392 220.926 43.8956 219.249 44.9076C218.688 45.2563 219.262 46.1079 219.823 45.7593Z"
              fill="#A7A7A7"
            />
            <path
              d="M221.92 54.1648C225.003 52.4405 228.246 51.1697 231.707 50.3998C232.371 50.2473 232 49.259 231.37 49.4181C227.834 50.2088 224.511 51.5342 221.346 53.3131C220.757 53.6212 221.331 54.4729 221.92 54.1648Z"
              fill="#A7A7A7"
            />
            <path
              d="M229.69 45.7437C231.192 44.9089 234.612 42.7218 235.857 44.8315C236.76 46.3461 237.096 48.5959 237.471 50.2895C237.624 50.9533 238.612 50.5822 238.453 49.9523C238.092 48.3672 237.685 46.8433 237.134 45.327C236.866 44.5348 236.569 43.3495 235.78 42.8787C234.861 42.3475 233.67 42.8553 232.797 43.1784C231.511 43.6324 230.307 44.208 229.123 44.8582C228.521 45.2341 229.129 46.0924 229.69 45.7437Z"
              fill="#A7A7A7"
            />
            <path
              d="M225.968 51.4656C227.425 52.4892 228.597 53.7041 229.615 55.1706C229.806 55.4545 230.193 55.4594 230.449 55.2275C232.359 53.3802 234.645 52.1346 237.24 51.4774C237.903 51.3249 237.532 50.3367 236.902 50.4958C234.125 51.1877 231.711 52.5493 229.673 54.5125C229.951 54.5315 230.229 54.5505 230.507 54.5694C229.469 53.0284 228.201 51.7597 226.683 50.689C226.085 50.3257 225.41 51.0751 225.968 51.4656Z"
              fill="#A7A7A7"
            />
            <path
              d="M226.014 90.6503C226.019 90.2637 225.99 89.8705 225.995 89.4839C226.36 89.238 226.718 89.026 227.131 88.895C227.361 88.799 227.625 88.7095 227.862 88.5795C228.335 88.3194 228.673 88.0329 228.969 87.5974C229.326 87.0327 229.37 86.4425 229.428 85.7844C229.434 85.5741 229.446 85.3299 229.452 85.1197C229.464 84.8755 230.136 82.1527 231.05 83.2468C231.48 83.7533 232.331 83.1794 231.901 82.6729C231.274 81.9165 230.473 81.8662 229.669 82.3788C228.297 83.274 228.582 84.7035 228.385 86.0744C228.09 88.1307 225.423 87.7167 224.822 89.5372C224.554 90.3659 224.742 91.2127 225.591 91.5543C226.176 91.8091 226.639 90.8777 226.014 90.6503Z"
              fill="#A155B9"
            />
            <path
              d="M230.296 88.56C230.985 89.3636 232.232 89.1133 232.607 88.0941C232.955 87.2108 232.509 86.2432 232.756 85.3401C233.102 84.1041 234.162 83.1833 235.402 82.9669C236.06 82.8483 235.98 81.8111 235.323 81.9297C234.123 82.1187 233.095 82.6935 232.402 83.721C232.065 84.1838 231.817 84.7344 231.704 85.3114C231.652 85.5829 231.633 85.861 231.62 86.1052C231.62 86.1052 231.673 88.5464 231.189 87.9588C230.718 87.4796 229.867 88.0535 230.296 88.56Z"
              fill="#A155B9"
            />
            <path
              d="M120.905 183.505C120.361 183.046 120.445 182.253 120.985 181.829C121.526 181.406 122.38 181.361 123.005 181.589C123.528 181.796 124.086 182.187 124.218 182.776C124.37 183.44 123.758 184.413 122.963 184.152C122.338 183.925 121.875 184.856 122.5 185.084C123.621 185.478 124.791 184.896 125.193 183.741C125.641 182.525 124.821 181.308 123.727 180.778C122.605 180.207 121.039 180.29 120.114 181.237C119.278 182.096 119.278 183.54 120.258 184.295C120.742 184.706 121.423 183.923 120.905 183.505Z"
              fill="#A155B9"
            />
            <path
              d="M90.6816 52.9385C91.0175 52.2993 91.36 51.6262 91.662 50.9805C91.8524 51.088 92.0428 51.1956 92.2332 51.3032C92.4981 51.39 92.7366 51.6126 92.9949 51.7334C93.3484 51.908 93.783 52.0279 94.1762 51.9988C95.1182 51.8653 95.5551 51.0696 95.7204 50.2211C95.8857 49.3726 95.7588 48.3966 96.5699 47.8501C97.2187 47.4128 98.1748 47.3877 98.8545 47.6963C101.091 48.6957 100.278 51.6023 98.9487 52.9991C98.5035 53.476 99.2868 54.1571 99.732 53.6802C101.203 52.0995 102.003 49.4371 100.455 47.6205C99.3086 46.2699 96.9609 46.0239 95.631 47.2444C94.7331 48.0559 95.0429 48.997 94.6277 50.0434C93.9378 51.7762 91.7162 49.6171 90.4829 49.7995C88.1585 50.157 88.2678 53.0318 90.1032 53.9179C90.7961 54.1586 91.2594 53.2272 90.6816 52.9385Z"
              fill="#A155B9"
            />
            <path
              d="M282.431 244.815C283.249 244.411 284.036 244.529 284.799 245.136C285.133 245.412 285.708 245.172 285.658 244.704C285.472 244.034 285.645 243.504 286.247 243.128C286.69 242.122 287.246 242.16 287.889 243.201C288.034 243.723 288.765 243.583 288.892 243.115C288.978 242.673 289.104 242.205 289.258 241.777C289.572 240.711 290.337 240.578 291.494 241.332C291.646 241.996 292.601 241.618 292.476 240.995C292.253 239.965 291.227 239.448 290.204 239.636C288.666 239.935 288.165 241.599 287.874 242.916C288.199 242.874 288.552 242.872 288.877 242.83C288.6 241.719 287.451 241.108 286.333 241.418C284.911 241.846 284.533 243.428 284.627 244.75C284.905 244.593 285.209 244.476 285.487 244.318C284.445 243.517 283.352 243.339 282.1 243.8C281.484 244.067 281.821 245.049 282.431 244.815Z"
              fill="#A155B9"
            />
            <path
              d="M283.456 99.0585C281.517 99.2444 279.923 100.554 279.309 102.443C278.714 104.229 279.357 106.715 281.354 107.315C282.821 107.742 284.645 106.336 284 104.766C283.313 103.047 281.194 103.797 280.202 104.731C277.38 107.457 278.146 114.018 282.978 113.445C284.313 113.282 286.172 112.059 285.744 110.461C285.212 108.49 282.866 110.041 282.159 110.96C280.696 112.859 279.836 115.827 281.718 117.744C283.19 119.228 285.677 118.938 286.611 117.041C286.832 116.627 286.28 116.026 285.861 116.367C282.944 118.863 283.949 123.287 287.724 124.163C288.362 124.323 288.561 123.305 287.923 123.145C285.091 122.488 284.193 119.142 286.428 117.252C286.19 117.03 285.918 116.801 285.679 116.578C284.793 118.413 282.477 117.645 281.838 116.041C281.281 114.559 281.903 112.813 282.908 111.634C283.144 111.328 284.371 109.911 284.716 111.035C284.881 111.631 283.711 112.214 283.332 112.351C282.167 112.723 280.809 112.282 280.166 111.241C279.49 110.193 279.483 108.783 279.816 107.615C280.009 106.983 280.291 106.263 280.702 105.779C280.877 105.602 281.087 105.431 281.262 105.254C282.269 104.605 282.189 104.836 280.981 105.975C280.289 105.91 280.076 103.931 280.128 103.483C280.18 103.035 280.334 102.607 280.515 102.22C281.099 101.03 282.2 100.258 283.529 100.13C284.214 100.052 284.134 99.0144 283.456 99.0585Z"
              fill="#A155B9"
            />
            <path
              d="M96.8118 84.2188C96.6989 83.3513 96.9868 82.5971 97.7234 82.0713C98.5411 81.4907 99.3358 81.7513 100.158 82.0524C100.749 82.2732 101.211 82.4337 101.854 82.2067C102.348 82.0211 102.753 81.5715 103.043 81.17C103.244 80.857 103.412 80.5374 103.546 80.2113C103.627 79.9803 103.741 79.7559 103.814 79.5589C104.041 78.7575 104.684 78.5305 105.71 78.8713C105.557 79.4756 106.399 79.6749 106.668 79.1988C107.091 78.4711 107.589 77.7226 108.395 77.3863C109.586 76.8786 111.053 77.3053 110.972 78.8045C110.948 79.4692 111.952 79.3828 112.009 78.7247C112.077 77.2934 110.925 76.1531 109.494 76.0856C107.75 75.9925 106.523 77.4092 105.764 78.7761C106.096 78.8762 106.423 79.0102 106.721 79.1036C106.988 78.0986 106.163 77.2686 105.152 77.2126C103.517 77.1054 103.099 78.8911 102.528 80.0129C102.125 80.8151 101.796 81.4203 100.615 81.1549C100.275 81.0888 99.9142 80.7718 99.5408 80.6991C98.896 80.5735 98.2048 80.6854 97.6229 80.9596C96.3375 81.5899 95.5846 82.9228 95.7679 84.3325C95.8592 84.9492 96.8898 84.9034 96.8118 84.2188Z"
              fill="#5222D0"
            />
            <path
              d="M280.4 154.161C282.837 154.636 285.224 152.908 285.731 150.303C286.239 147.697 284.675 145.2 282.238 144.725C279.801 144.25 277.414 145.978 276.907 148.583C276.399 151.189 277.963 153.686 280.4 154.161Z"
              fill="#5222D0"
            />
            <path
              d="M41.5203 108.711C43.1324 109.025 44.6909 107.988 45.0013 106.395C45.3118 104.802 44.2566 103.255 42.6445 102.941C41.0324 102.627 39.4739 103.664 39.1635 105.258C38.853 106.851 39.9082 108.397 41.5203 108.711Z"
              fill="#5222D0"
            />
            <path
              d="M60.7888 239.996C62.1384 240.259 63.4635 239.287 63.7483 237.825C64.0332 236.363 63.1701 234.964 61.8204 234.701C60.4707 234.438 59.1457 235.41 58.8608 236.873C58.5759 238.335 59.4391 239.733 60.7888 239.996Z"
              fill="#5222D0"
            />
            <path
              d="M274.131 159.034C276.961 159.586 279.679 157.86 280.202 155.179C280.724 152.499 278.853 149.879 276.022 149.327C273.192 148.776 270.474 150.502 269.951 153.182C269.429 155.863 271.3 158.483 274.131 159.034Z"
              fill="#A155B9"
            />
            <path
              d="M192.996 35.0009C194.439 35.2821 195.864 34.2032 196.178 32.5911C196.492 30.9791 195.576 29.4442 194.133 29.163C192.69 28.8818 191.265 29.9607 190.951 31.5728C190.637 33.1848 191.552 34.7197 192.996 35.0009Z"
              fill="#A155B9"
            />
            <path
              d="M306.117 218.534C308.142 218.928 310.138 217.424 310.576 215.175C311.015 212.925 309.729 210.782 307.704 210.388C305.68 209.993 303.683 211.497 303.245 213.747C302.807 215.996 304.093 218.139 306.117 218.534Z"
              fill="#A155B9"
            />
            <path
              d="M24.4323 141.809C28.4813 142.598 32.418 139.879 33.2251 135.737C34.0323 131.594 31.4042 127.596 27.3553 126.807C23.3063 126.018 19.3697 128.737 18.5625 132.88C17.7554 137.023 20.3834 141.02 24.4323 141.809Z"
              fill="#A155B9"
            />
            <path
              d="M97.5801 128.588C108.545 155.125 119.602 181.663 130.567 208.108C175.165 192.628 219.854 177.055 264.452 161.575C253.763 136.052 243.074 110.436 232.478 84.9121C187.512 99.4707 142.546 114.029 97.5801 128.588Z"
              fill="white"
              stroke="#A155B9"
              strokeWidth="3"
              stroke-miterlimit="10"
            />
            <path
              d="M98.5937 130.891C130.475 135.591 162.08 141.488 193.409 148.583C195.16 148.951 195.897 146.279 194.146 145.911C162.725 138.816 131.12 132.826 99.3309 128.219C97.5801 128.035 96.843 130.707 98.5937 130.891Z"
              fill="#A155B9"
            />
            <path
              d="M129.093 170.697C138.307 165.169 148.259 161.759 158.855 160.285C158.303 160.009 157.75 159.64 157.197 159.364C159.04 165.353 160.79 171.25 162.633 177.239C163.186 178.898 165.858 178.253 165.305 176.502C163.463 170.513 161.712 164.616 159.869 158.626C159.593 157.797 158.948 157.521 158.21 157.705C147.338 159.179 137.202 162.773 127.803 168.394C126.145 169.223 127.527 171.619 129.093 170.697Z"
              fill="#A155B9"
            />
            <path
              d="M136.833 191.429C145.587 187.836 154.156 183.781 162.633 179.451C164.2 178.621 162.818 176.226 161.251 177.055C153.05 181.294 144.665 185.164 136.096 188.757C134.438 189.494 135.175 192.167 136.833 191.429Z"
              fill="#A155B9"
            />
            <path
              opacity="0.08"
              d="M151.76 112.647C156.92 125.179 162.081 137.71 167.241 150.241C175.81 152.176 184.287 154.112 192.856 155.954C202.163 162.404 211.377 168.947 220.684 175.397C234.782 170.513 248.972 165.629 263.07 160.838C252.289 136.42 241.6 111.91 230.819 87.4919C216.721 91.7305 188.433 100.3 188.433 100.3L180.97 101.866C180.97 101.866 178.206 100.3 151.76 112.647Z"
              fill="#A155B9"
            />
            <path
              d="M230.854 86.2018C220.626 107.487 208.187 127.666 193.72 146.279C192.615 147.661 194.55 149.596 195.655 148.214C210.306 129.417 222.93 109.053 233.25 87.5839C233.987 86.0175 231.591 84.6353 230.854 86.2018Z"
              fill="#A155B9"
            />
            <path
              d="M127.101 169.039C128.759 176.595 131.524 183.69 135.302 190.324C136.131 191.891 138.527 190.508 137.697 188.942C134.012 182.4 131.339 175.581 129.773 168.302C129.404 166.551 126.732 167.288 127.101 169.039Z"
              fill="#A155B9"
            />
            <path
              d="M207.542 149.873C217.678 144.436 228.458 140.658 239.7 138.355C241.451 137.986 240.714 135.314 238.963 135.683C227.445 137.986 216.48 141.948 206.16 147.477C204.501 148.306 205.975 150.702 207.542 149.873Z"
              fill="#A155B9"
            />
            <path
              d="M210.859 157.797C217.033 155.125 223.206 152.453 229.288 149.781C230.946 149.044 229.472 146.648 227.906 147.385C221.732 150.057 215.558 152.73 209.477 155.402C207.818 156.139 209.293 158.442 210.859 157.797Z"
              fill="#A155B9"
            />
          </svg>
        </div>

        <Typography type="button" weight={"bold"} className="mb-6 text-[20px]">
          {t("RESUME_IS_SENT")}
        </Typography>
        <Button intent="primary" onClick={closeAndResetModal} text={t("CLOSE")} className="mt-auto w-full" />
      </div>
    </ModalStep>
  )
}

export default ResumeSuccessStep
